import styled from "styled-components"
import { CommonParagraph, CommonSubtitle } from "../../../common/Texts"

export const Wrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  margin-bottom: 5%;
  padding: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  height: auto;
  width: 700px;
  &.mobile {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`

export const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #f2f2f2 0% 0% no-repeat padding-box;
`

export const Title = styled(CommonSubtitle)`
  font: normal normal 600 24px/22px Cormorant Garamond;
  margin: 0;
`

export const List = styled.div`
  margin: 30px 0;
`

export const TH = styled.div`
  width: 95%;
  margin: auto;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  gap: 17px;
  border-bottom: 1px solid #d8d8d8;
  &.mobile {
    display: none;
  }
`
export const ProductTH = styled(CommonSubtitle)`
  font-size: 14px;
  margin: 0;
  letter-spacing: -0.35px;
  width: 330px;
`
export const VariantTH = styled(CommonSubtitle)`
  font-size: 14px;
  margin: 0;
  letter-spacing: -0.35px;
  width: 150px;
`
export const QtyTH = styled(CommonSubtitle)`
  font-size: 14px;
  margin: 0;
  letter-spacing: -0.35px;
`
export const PriceTH = styled(CommonSubtitle)`
  font-size: 14px;
  margin: 0;
  letter-spacing: -0.35px;
  text-align: end;
  width: 70px;
`

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 15px 0px 15px;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  width: auto;
  &.mobile {
    flex-direction: column;
    padding: 0;
  }
`

export const Column = styled.div`
  margin-bottom: 1%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  &.mobile{
    flex-direction: row;
    background: white;
    padding-bottom: 10px;
  }
`

export const TextDiscount = styled(CommonParagraph)`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`

export const Form = styled.form`
  display: flex;
  margin-top: 2%;
`

export const DiscountInput = styled.input`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cfcfcf;
  border-radius: 50px;
  padding: 0 20px;
`

export const Add = styled.button`
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
  background: none;
  border: none;
  cursor: pointer;
`

export const EditCart = styled.button`
  border: none;
  text-decoration: underline;
  font: normal normal normal 12px/22px Poppins;
  letter-spacing: -0.3px;
  color: #2b3944;
  cursor: pointer;
  background: transparent;
  &.mobile{
    background: white;
  }
`

export const RowPrice = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  :last-child {
    border: none;
  }
  &.mobile {
    width: 95%;
  }
`
export const AmountText = styled(CommonParagraph)`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  width: 100px;
  margin: 0;
  &.mobile {
    justify-content: flex-start;
  }
`

export const Amount = styled(CommonParagraph)`
  display: flex;
  width: 100px;
  font-size: 14px;
  justify-content: end;
  margin: 0;
  &.mobile {
    font: normal normal normal 16px/17px Poppins;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
`

export const Promo = styled(Amount)`
  font-size: 14px;
  &.mobile {
    font-size: 0.9em;
    text-align: right;
  }
`

export const RowTotalPrice = styled.div`
  background: #2b3944 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: end;
  padding: 15px;
  &.mobile {
    align-items: center;
    justify-content: space-between;
  }
`
export const TotalAmountText = styled(AmountText)`
  color: white;
  &.mobile {
    font-size: 18px;
    width: auto;
  }
`

export const TotalAmount = styled(Amount)`
  color: white;
  font: normal normal bold 20px/17px Poppins;
  &.mobile {
    font: normal normal bold 26px/17px Poppins;
    letter-spacing: -0.65px;
    width: auto;
  }
`