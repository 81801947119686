import React, { ReactNode } from "react"
import { CommonWrapper } from "../../common/CommonWrapper"
import { enumFrom } from "./../../common/ImageWithLinkOrNot"
import { navigate } from "gatsby"
import {
  Wrapper,
  Top,
  Logo,
  Corps,
  Bottom,
  BottomLink,
} from "./PageCheckout.style"
import { SEO } from "../../common/SEO"
import { useDetectDevice } from "../../../lib/useDetectDevice"


interface Props {
  data: any
  ActiveCheckoutStep: ReactNode
}

export function PageCheckoutView({ data, ActiveCheckoutStep }: Props) {

  if (typeof window !== "undefined") {
    var body = document.querySelector("body")
    if (body) {
      body.style.margin = "0"
      body.style.overflowX = "hidden"
      body.style.overflowY = "auto"
    }
  }

  const isMobile = useDetectDevice()
  let className = isMobile?"mobile":""
  return (
    <CommonWrapper>
      <SEO title="Votre Commande" description="Poursuivez et validez votre commande sur Grandes-Marques.fr."/>
      <Wrapper>
        <Top>
          <Logo
            onClick={() => navigate("/")}
            data={data.strapiGmHeader.BandeauHeader.Logo}
            from={enumFrom.STRAPI}
          />
        </Top>
        <Corps className={className}>{ActiveCheckoutStep}</Corps>
        <Bottom className={className}>
          <BottomLink link={"../../../" + data.strapiGmFooter.Colonne1[4].Link} >Assistance Grandes Marques</BottomLink>
        </Bottom>
      </Wrapper>
    </CommonWrapper>
  )
}
