import styled from "styled-components"
import { Link } from "../../common/Link"
import { ImageWithLinkOrNot } from "./../../common/ImageWithLinkOrNot"
import { CommonParagraph, CommonTitle } from "../../common/Texts"
import { Icon as Iconbase } from "../../common/Icon"
import { Wrapper as WrapperProductList } from "./ProductList/ProductList.style"

export const Step = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.mobile {
    grid-template-columns: 15% 60% 25%;
  }
`

export const Column = styled.div`
  grid-template-rows: 30% 70%;
`

export const WrapperCheckout = styled.div`
  width: auto;
  max-width: 1250px;
  margin: auto;
  flex-direction: column;
  gap: 20px;
`

export const OrderTitle = styled(CommonTitle)`
  font: normal normal 600 40px/22px Cormorant Garamond;
  letter-spacing: -1px;
  text-align: center;
  padding: 50px;
`

export const InfoCheckout = styled(WrapperProductList)`
  min-width: 365px;
  width: auto;
  max-width: 535px;
  &.mobile{
    min-width: auto;
    width: auto;
  }
`
export const OrderInfo = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`

export const StepTitle = styled(CommonParagraph)`
  text-align: center;
  font: normal normal medium 16px/22px Poppins;
  font-weight: 600;
  letter-spacing: -0.4px;
`

export const Content = styled(CommonParagraph)`
width: 200px;
text-align: justify;
  font: normal normal normal 14px/17px Poppins;
  letter-spacing: -0.35px;
  &.mobile {
    font-size: 0.75rem;
  }
`

export const Action = styled.div`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: 14px;
  color: #2b3944;
  text-decoration: underline;
  cursor: pointer;
`

export const Wrapper = styled.div`
  width: auto;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
`

export const Wrapper03 = styled.button`
  min-width: 500px;
  width: 100%;
  background: none;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  border: none;
  cursor: pointer;
  &.mobile {
    justify-content: flex-start;
    min-width: 0;
  }
`

export const Background = styled.div`
  border: none;
  background-color: rgba(213, 213, 213, 0.5);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  &.mobile{
    border: solid;
  }
`

export const BackgroundForm = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: absolute;
  top: 3.5em;
  right: 15em;
  left: 15em;
  background: white;
  max-height: 95%;
  width: auto;
  overflow-y: auto;
  z-index: 10;
  &.mobile{
    position: static;
    border: #d9d9d9 1px solid;
  }
`

export const Wrapper04 = styled.div`
  height: 20px !important;
  width: 20px !important;
  margin-left: 5em;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: white;
  background-color: ${({ theme }) => theme.palette.button.homepage};
  font-weight: ${({ theme }) => theme.typography.body.fontWeight};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.info.fontSize};
`

export const Wrapper05 = styled.div`
  font-weight: ${({ theme }) => theme.typography.body.fontWeight};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: 120%;
  &.mobile {
    margin-left: 20%;
  }
`

export const Icon = styled(Iconbase)`
  transform: rotate(0deg);
  z-index: 1;
  &.active {
    transform: rotate(90deg);
  }
`

export const WrapperView = styled.div`
  margin: 25px;
`

export const StyledProductList = styled.div`
  margin-left: 0;
  height: auto;
  &.mobile {
    margin-bottom: 1.5em;
    width: 100%;
  }
`

export const Top = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
`

export const Logo = styled(ImageWithLinkOrNot)`
  height: 50px;
  object-fit: contain;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  min-width: 20vw;
  align-self: center;
`

export const Corps = styled.div`
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-top: 1px solid #d9d9d9;
  margin-top: 1em;
  overflow: auto;
  width: 100%;
  &.mobile {
    border-top: none;
  }
`
export const Line = styled.div`
  width: 80%;
  height: 1px;
  background-color: #d9d9d9;
  margin-left: 2em;
`

export const Bottom = styled.div`
  width: 100%;
  height: 3em;
  font-family: ${({ theme }) => theme.typography.info.fontFamily};
  background-color: ${({ theme }) => theme.palette.primary.main};
  justify-content: center;
  text-align: center;
  align-content: center;
  align-items: center;
  &.mobile {
    margin-top: 1em;
    position: relative;
  }
`

export const BottomLink = styled(Link)`
  text-decoration: underline;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-items: center;
  color: white;
`