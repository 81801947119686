import { useStaticQuery, graphql } from "gatsby"

// export const useHeaderQuery = (): IHeaderData => {
export const useCheckoutQuery = () => {
  const data = useStaticQuery(
    graphql`
    query CheckoutQuery {
      strapiGmHeader {
        BandeauHeader {
          Logo {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            alternativeText
          }
        }
      }

      strapiGmFooter {
        Colonne1Titre
        Colonne1 {
          ... on Node {
            ...dynamicFooterComponent
          }        
        }
      }
    }
    
    fragment dynamicFooterComponent on STRAPI__COMPONENT_GLOBAL_FOOTER {
      Text
      Link
    }
    `
  )
  return data
}
