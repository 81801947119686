import React from 'react';

import { PageWaitIpnView } from './PageWaitIpn.view';

interface Props {
    className?: string;
}

export function PageWaitIpnContainer({ className }: Props) {
    return <PageWaitIpnView className={className} />;
}
