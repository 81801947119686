import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Spinner } from '../../common/Spinner';
import { useCheckoutQuery } from '../PageCheckout/PageCheckout.query';
import { PageCheckoutView } from '../PageCheckout/PageCheckout.view';

interface Props {
    className?: string;
}

export function PageWaitIpnView({ className }: Props) {
    const data = useCheckoutQuery()

    useEffect(() => {
        setTimeout(() => {
            navigate('/app/checkout/payment-final')
        }, 5000);
    }, [])

    return (
        <PageCheckoutView
            data={data}
            ActiveCheckoutStep={<Spinner />}
        />
    );
}
